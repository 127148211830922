// Volumetry.tsx

import React, { useEffect, useState, MouseEvent } from "react";
import { computeVolumetry } from "../../../../helpers/compute";

import { FormType } from "../../../../types/Form";
import { FilterType, FiltersType } from "../../../../types/Types";
import { RangeType } from "../../../../types/Volumetry";

import VolumetryChart from "./Chart";
import RangeValues from "./RangeValues";
import Filters from "./Filters";

import { useLangContext } from "../../../../contexts/LangContext";

import allData from "../../../../data.json";

import { LangData } from "../../../Utilities/Props";

import "../../../../styles/Volumetry.css";
import { useComputeStateContext } from "../../../../contexts/ComputeStateContext";
import Loader from "../../../Utilities/Loader";


interface VolumetryProps {
    form: FormType;
    filters?: FilterType[];
    onClick?: (e: MouseEvent) => void;
    answersIds?: number[];
}

export default function Volumetry({ form, filters, answersIds } : VolumetryProps) {
    const lang = useLangContext().lang;
    const data = allData["volumetry"];

    const computeState = useComputeStateContext().computeState;

    const [ count, setCount ] = useState<Record<string, number> | Record<string, Record<string, number>>>({});
    const [ volumetry, setVolumetry ] = useState<Record<string, number>>({});
    const [ asc, setAsc ] = useState(false);

    const [ currentFilters, setCurrentFilters ] = useState<FiltersType>({} as FiltersType);
    const [ previousFilters, setPreviousFilters ] = useState<FiltersType>({} as FiltersType);

    const [ currentAnswersIds, setCurrentAnswersIds ] = useState<number[]>([]);

    const [ rangeValues, setRangeValues ] = useState<RangeType>({} as RangeType);

    const [ computing, setComputing ] = useState(false);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    function compute(allFilters : FilterType[], localCurrentAnswersIds : number[]) {
        if (form.id) {
            setComputing(true);
            computeVolumetry(form.id, allFilters, localCurrentAnswersIds).then((response) => {
                setCount(response.count);
                if (allFilters.some((filter) => filter === FilterType.QUESTION)) {
                    setVolumetry(response.count[currentFilters[FilterType.QUESTION].id]);
                } else {
                    setVolumetry(response.count);
                }
                setComputing(false);
            });
        }
    }

    useEffect(() => {
        if (form.id) {
            const allFilters = [...(filters || []), ...Object.keys(currentFilters) as FilterType[]];
            let localCurrentAnswersIds = answersIds || [];

            if (filters) {
                if (!(filters.some((filter) => filter === FilterType.GROUP)) && (Object.keys(currentFilters) as FilterType[]).some((filter) => filter === FilterType.GROUP)) {
                    const groupAnswersIds = computeState.groups[currentFilters[FilterType.GROUP].id].group.map((answer) => answer.id);

                    if (localCurrentAnswersIds.length > 0) {
                        // on garde les ids qui sont dans groupAnswersIds et dans currentAnswersIds
                        localCurrentAnswersIds = localCurrentAnswersIds.filter((answerId) => groupAnswersIds.includes(answerId));
                    } else {
                        localCurrentAnswersIds = groupAnswersIds;
                    }
                }
            } else {
                if ((Object.keys(currentFilters) as FilterType[]).some((filter) => filter === FilterType.GROUP)) {
                    localCurrentAnswersIds = computeState.groups[currentFilters[FilterType.GROUP].id].group.map((answer) => answer.id);
                }
            }

            // Handle the case where the GROUP filter is not in both previous and current filters
            if ((currentFilters[FilterType.GROUP] && !previousFilters[FilterType.GROUP]) || (!currentFilters[FilterType.GROUP] && previousFilters[FilterType.GROUP])) {
                compute(allFilters, localCurrentAnswersIds);
            // Handle the case where the GROUP filter is in both previous and current filters but with different values
            } else if ((currentFilters[FilterType.GROUP] && previousFilters[FilterType.GROUP]) && (currentFilters[FilterType.GROUP].id !== previousFilters[FilterType.GROUP].id)) {
                compute(allFilters, localCurrentAnswersIds);
            // Handle the case where the QUESTION filter is not in both previous and current filters 
            } else if ((currentFilters[FilterType.QUESTION] && !previousFilters[FilterType.QUESTION]) || (!currentFilters[FilterType.QUESTION] && previousFilters[FilterType.QUESTION])) {
                compute(allFilters, localCurrentAnswersIds);
            // Handle the case where the QUESTION filter is in both previous and current filters but with different values
            } else if ((currentFilters[FilterType.QUESTION] && previousFilters[FilterType.QUESTION]) && (currentFilters[FilterType.QUESTION].id !== previousFilters[FilterType.QUESTION].id)) {
                setVolumetry(count[currentFilters[FilterType.QUESTION].id] as Record<string, number>);
            // Handle the case where the localCurrentAnswersIds are different from the previous ones
            } else if (localCurrentAnswersIds !== currentAnswersIds) {
                compute(allFilters, localCurrentAnswersIds);
            }

            setCurrentAnswersIds(localCurrentAnswersIds);
            setPreviousFilters(currentFilters);
        }
    }, [ form.id, currentFilters, answersIds ]);


    useEffect(() => {
        if (Object.keys(volumetry).length === 0) return;

        setRangeValues({ min: Math.min(...Object.values(volumetry)), max: Math.max(...Object.values(volumetry)) });
    }, [ volumetry ]);


    return (
        <div className="widget volumetry" onClick={(e) => e.stopPropagation()}>
            <h3>{(data["title"] as LangData)[lang]}</h3>

            <div className="controls">
                <Filters
                    orderData={data["order"]}
                    asc={asc}
                    setAsc={setAsc}
                    
                    filterData={data['filters']}
                    defaultFilters={filters || []}
                    filters={currentFilters}
                    setFilters={setCurrentFilters}
                />

                <RangeValues
                    rangeValues={rangeValues}
                    setRangeValues={setRangeValues}
                    values={Object.values(volumetry || {})}
                    data={data["range"]}
                />
            </div>
            
            <div className="chart">
                {computing &&
                    <div className="overlay">
                        <Loader/>
                    </div>
                }

                <VolumetryChart
                    data={volumetry || {}}
                    maxLabels={30}
                    asc={asc}
                    min={rangeValues.min}
                    max={rangeValues.max}
                    key={`${windowSize.width}-${windowSize.height}`} // Utiliser la taille de la fenêtre comme clé pour forcer le re-rendu
                />
            </div>
        </div>
    );
}
