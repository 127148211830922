import React from "react";
import { LangData } from "./Props";
import { useLangContext } from "../../contexts/LangContext";

import "../../styles/Information.css";


interface SelectProps<T> {
    value: T;
    setValue: (value: T) => void;
    options: Record<string, LangData>;
}

interface InformationProps<T> {
    label: LangData;
    value: number | string;
    select?: SelectProps<T>;
}

export default function Information<T>({ label, value, select }: InformationProps<T>) {
    const lang = useLangContext().lang;

    return(
        <div className="information">
            <span className="label">{label[lang]}</span>

            {select ? (
                    <select value={value} onChange={(e) => {select.setValue(e.target.value as T)}}>
                        {Object.entries(select.options).map(([key, value]) => (
                            <option key={key} value={key}>{value[lang]}</option>
                        ))}
                    </select>
                ) : (
                    <span className="value">{value}</span>
                )
            }
        </div>
    )
}
