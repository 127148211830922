// CSVParser.tsx

import React, { useState, useEffect } from "react";

import Tab from "../../Utilities/Tab";
import Button from "../../Utilities/Button";

import '../../../styles/Dashboard/CSVParser.css';
import '../../../styles/Dashboard/Dashboard.css';
import '../../../styles/Dashboard/Results.css';

import HeaderForm from "../Form/HeaderForm";

import Questions from "../Form/Questions";
import Primary from "./Primary";

import { FormType } from "../../../types/Form";
import { dataType, fileType } from "../../../types/Upload";
import { InputType } from "../../../types/Types";

import { submitForm } from "../../../helpers/forms";
import { postAnswersWithoutAttendees } from "../../../helpers/answers";
import { CSVToJson } from "../../../helpers/CSV&JSON";
import { computeGroups } from "../../../helpers/compute";

import allData from '../../../data.json';

import Groups from "./Groups";
import Volumetry from "./Volumetry/Volumetry";

import Upload from "./Upload";
import { useComputeStateContext } from "../../../contexts/ComputeStateContext";


export default function CSVParser() {
    const computeDispatch = useComputeStateContext().computeDispatch;

    const [ dataFile, setDataFile ] = useState<dataType>(null);
    const [ file, setFile ] = useState<fileType>(null);

    const [ dataSent, setDataSent ] = useState(false);

    const [ form, setForm ] = useState<FormType>({} as FormType);
    const [ inputs, setInputs ] = useState<InputType[]>([]);

    useEffect(() => {
        if (dataFile && dataFile.length > 0) {
            const headers = dataFile[0];
            const currentInputs : InputType[] = [];
            headers.forEach((header) => {
                currentInputs.push({
                    title: header,
                    importance: 0,
                    type: 'text'
                });
            });
            setInputs(currentInputs);
        }

        if (file) {
            const newForm = {
                title: file.name,
                description: '',
                fixedSize: 2
            } as FormType;

            if (dataFile && dataFile.length > 0) {
                newForm.nbAnswers = dataFile.length - 1;
            }

            setForm(newForm);
        }

    }, [ dataFile, file ]);


    async function handleCompute() {
        setDataSent(false);

        const submitedForm = await submitForm({form, inputs});
        if (submitedForm && submitedForm.id) {
            setForm({...form, id: submitedForm.id});
        }

        if (submitedForm && submitedForm.id && dataFile) {
            const jsonData = CSVToJson(dataFile, inputs.map((input) => input.title));
            await postAnswersWithoutAttendees(jsonData, submitedForm.id);
            setDataSent(true);
        }
    }

    useEffect(() => {
        if (dataSent && form.id) {
            computeGroups(form.id).then((response) => {
                computeDispatch({ type: "SET_GROUPS", payload: response.groups });
                computeDispatch({ type: "SET_QUESTIONS", payload: response.questions });
            });
        }
    }, [ dataSent ]);

    return (
        <Tab title="CSVParser">
            <Upload setDataFile={setDataFile} setFile={setFile} dataFile={dataFile}/>

            {dataFile && dataFile.length > 0 && (
                <>
                    <HeaderForm form={form} setForm={setForm} data={allData['Form']['header']}/>
                    <Questions inputs={inputs} setInputs={setInputs}/>

                    <Button title="Compute" onClick={handleCompute} className="compute-button"/>
                    
                    {
                        dataSent &&
                            <>
                                <Primary inputs={inputs}/>
                                <Groups form={form}/>
                                <Volumetry form={form}/>
                            </>
                    }
                </>
            )}
        </Tab>
    )
}
