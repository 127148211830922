import React, { useState } from "react";

import "../../../styles/color.css";
import { BlocType, InputType } from "../../../types/Types";
import FormInput from "../../Utilities/FormInput";
import Input from "../Form/Input";

import Button from "../../Utilities/Button";

interface SideProps {
    column : number;
    inputs : InputType[];
    setInputs : (inputs: InputType[]) => void;
    removeInput : (index: number) => void;
    addInput : () => void;
}

export default function Side({ column, inputs, setInputs, removeInput, addInput } : SideProps) {
    const [ info, setInfo ] = useState<BlocType>({title: "", description: ""});

    const side = column === 1 ? "left" : "right";

    return (
        <>
            <div style={{gridColumn: column, gridRow: 1}} className={`${side} foreground`}>
                <h1>Side</h1>
                <FormInput
                    label="Title"
                    value={info.title}
                    setValue={(value) => setInfo({...info, title: value})}
                />
                <FormInput
                    label="Description"
                    value={info.description}
                    setValue={(value) => setInfo({...info, description: value})}
                />

                <Button title="Add Input" onClick={addInput}/>
            </div>

            {
                inputs.map((input, index) => (
                    <Input 
                        key={index}
                        input={input}
                        setInput={(input) => {
                            const updatedInputs = [...inputs];
                            updatedInputs[index] = input;
                            setInputs(updatedInputs);
                        }}
                        removeInput={() => removeInput(index)}
                        style={{
                            gridColumn: column,
                            gridRow: index + 2
                        }}
                        className={`${side} foreground`}
                    />
                ))
            }

            <div
                className={`background ${side} widget`}
                style={{gridColumn : column, gridRowStart: 1, gridRowEnd : inputs.length + 2}}
            />
        </>
    )
}
