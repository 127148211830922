import React, { useState } from "react";
import Tab from "../../Utilities/Tab";

import allData from "../../../data.json";

import "../../../styles/Dashboard/MirrorForm.css";
import HeaderForm from "../Form/HeaderForm";
import { FormType } from "../../../types/Form";
import Side from "./Side";
import { InputType } from "../../../types/Types";
import Section from "./Section";


export default function MirrorForm() {
    const [ form, setForm ] = useState<FormType>({} as FormType);
    const [ leftInputs, setLeftInputs ] = useState<InputType[]>([]);
    const [ rightInputs, setRightInputs ] = useState<InputType[]>([]);

    function addInput() {
        setLeftInputs([...leftInputs, {title: "", importance: 0, type: ""}]);
        setRightInputs([...rightInputs, {title: "", importance: 0, type: ""}]);
    } 

    function removeInput(index: number) {
        let updatedInputs = [...leftInputs];
        updatedInputs.splice(index, 1);
        setLeftInputs(updatedInputs);

        updatedInputs = [...rightInputs];
        updatedInputs.splice(index, 1);
        setRightInputs(updatedInputs);
    }

    return (
        <Tab title="MirrorForm">
            <HeaderForm form={form} setForm={setForm} data={allData['Form']['header']}/>
            
            <div className="sides">
                <Side column={1} inputs={leftInputs} setInputs={setLeftInputs} removeInput={removeInput} addInput={addInput}/>
                <Side column={2} inputs={rightInputs} setInputs={setRightInputs} removeInput={removeInput} addInput={addInput}/>

                {leftInputs.map((input, index) => (
                    <Section key={index} index={index}/>
                ))}
            </div>
        </Tab>
    )
}
