import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../Utilities/Page";
import Attendee from "../Dashboard/Attendees/Attendee";
import { AttendeeWithId } from "../../types/Attendee";

import "../../styles/FillForm.css";
import Button from "../Utilities/Button";
import { addAttendee } from "../../helpers/attendees";


export default function FillFormByLink() {
    const { uuid } = useParams<{ uuid: string }>();
    const navigate = useNavigate();

    const [ valid, setValid ] = useState(false);

    const [ attendee, setAttendee ] = useState<AttendeeWithId>({
        id: '',
        firstName: '',
        lastName: '',
        email: ''
    });

    function handleSubmit() {
        console.log(uuid);
        
        if (uuid) {
            addAttendee(attendee, uuid).then((answerUUID) => {
                navigate(`/fillForm/${answerUUID}`);
            });
        }
    }

    return (
        <Page title="FillFormByLink">
            <div className="widget">
                <Attendee attendee={attendee} setAttendee={setAttendee} setValid={setValid}/>

                {valid == true &&
                    <Button title="submit" onClick={handleSubmit}/>
                }
            </div>
        </Page>
    )
}