import React from "react";

import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


interface VolumetryChartProps {
    data : Record<string, number>;
    maxLabels? : number;
    asc: boolean;
    min : number;
    max : number;
}

export default function VolumetryChart({ data, maxLabels, asc, min, max } : VolumetryChartProps) {
    // Filtrer les données en fonction de la plage
    data = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value >= min && value <= max) // eslint-disable-line @typescript-eslint/no-unused-vars
    );

    const labels = Object.keys(data);
    const values = Object.values(data);

    // Créer un tableau de tuples [clé, valeur], puis trier par la valeur de manière décroissante
    let sortedData = labels
        .map((label, index) => [label, values[index]] as [string, number])
    
    if (!asc) {
        sortedData = sortedData.sort((a, b) => b[1] - a[1])
    } else {
        sortedData = sortedData.sort((a, b) => a[1] - b[1])
    }

    // Séparer les clés et valeurs triées
    let sortedLabels = sortedData.map((item) => item[0]);
    let sortedValues = sortedData.map((item) => item[1]);

    if (maxLabels) {
        sortedLabels = sortedLabels.slice(0, maxLabels);
        sortedValues = sortedValues.slice(0, maxLabels);
    }

    const chart_data = {
        labels: sortedLabels,
        datasets: [
            {
                label: "Token Frequency",
                data: sortedValues,
                backgroundColor: "rgba(75, 192, 192, 0.5)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
            },
        ],
    };

    const options = {
        indexAxis: 'y' as const,
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: "Token Frequency",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Frequency",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Token",
                },
                ticks: {
                    autoSkip: false,
                },
                beginAtZero: true, // Le graphique commence à 0
            },
        },
    };

    return (
        <Bar data={chart_data} options={options}/>
    );
}
