import React from "react";
import { BlocProps, LangData } from "../../Utilities/Props";
import "../../../styles/Vitrine/WhatsNew.css";
import { useLangContext } from "../../../contexts/LangContext";

interface imageType {
    url: string;
    alt: LangData;
    title: LangData;
}

interface CardType extends BlocProps {
    image?: imageType;
    date: LangData;
}

interface CardProps {
    data : CardType;
}

export default function Card( { data } : CardProps) {
    const lang = useLangContext().lang;

    return (
        <div className="whatsNew-card widget">
            {
                data.image &&
                <img src={data.image.url} alt={data.image.alt[lang]} title={data.image.title[lang]}/>
            }

            <div className="description">
                <h3>{data.title[lang]}</h3>
                {data.subtitle && <h4>{data.subtitle[lang]}</h4>}
                <p>{data.description[lang]}</p>
                <span className="date">{data.date[lang]}</span>
            </div>
        </div>
    );
}
