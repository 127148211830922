// Nav.tsx

import React, { useEffect, useState } from "react";

import HarmateLogo from "../Utilities/Logos/HarmateLogo";
import Button from "../Utilities/Button";
import { LangData } from "../Utilities/Props";

import { useLangContext } from "../../contexts/LangContext";
import { useScrollContext } from "../../contexts/ScrollContext";

import { DataProps } from "../../types/Types";
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';


export default function Nav({ data } : DataProps) {
    const lang = useLangContext().lang;
    const navigate = useNavigate();

    const [ scale, setScale ] = useState(0);
    const { scroll, setScroll } = useScrollContext();

    const [ opacity, setOpacity ] = useState(0);

    const headerHeight = scale * 3;
    const maxOpacity = 0.9;

    const isLargeScreen = useMediaQuery('(min-width : 800px), (orientation : landscape)');

    useEffect(() => {
        function handleResize() {
            setScale((window.innerWidth + window.innerHeight) * 0.5 * 0.01);
        }

        // Calcul initial
        handleResize();

        // Ajouter un écouteur d'événement pour recalculer lors du redimensionnement de la fenêtre
        window.addEventListener('resize', handleResize);

        // Nettoyage de l'écouteur d'événement lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [ window.innerHeight, window.innerWidth ]);

    useEffect(() => {
        if(scroll && scroll > headerHeight) {
            setOpacity(maxOpacity);
        } else if (scroll) {
            setOpacity((scroll / headerHeight) * maxOpacity);
        } else {
            setOpacity(0);
        }
    }, [ scroll ]);

    function handleClick() {
        setScroll(0);
        navigate('/')
    }

    return(
        <nav style={{
            backgroundColor : `rgba(255, 255, 255, ${opacity})`,
            borderBottom: `solid calc(0.1 * var(--scale)) rgba(206, 206, 206, ${opacity})`}}
        >
            <div onClick={handleClick} className="harmate-logo-container">
                <HarmateLogo/>
                <span>Harmate</span>
            </div>

            <div className="buttons">
                {isLargeScreen &&
                    <>
                        <Button
                            to="/whatsNew"
                            title={(data['whatsNew'] as LangData)[lang]}
                            onClick={() => setScroll(0)}
                            className="whats-new-button"
                        />
                        <Button
                            to="/signUp"
                            title={(data['signUp'] as LangData)[lang]}
                        />
                    </>
                }
                <Button
                    to="/signIn"
                    title={(data['signIn'] as LangData)[lang]}
                    className="signin"
                />
            </div>
        </nav>
    )
}
