// SignIn.tsx

import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';

import Page from "../Utilities/Page";

import "../../styles/SignIn.css";

import { verifyToken, signIn } from "../../helpers/signIn";
import { useLangContext } from "../../contexts/LangContext";

import allData from "../../data.json";
import { LangData } from "../Utilities/Props";
import Button from "../Utilities/Button";
import Back from "../Utilities/Back";
import { useNavigate } from "react-router-dom";


export default function SignIn() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const lang = useLangContext().lang;
    const data : Record<string, LangData> = allData["SignIn"];


    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        try {
            const token = await signIn(email, password);
            if (token) {
                Cookies.set('token', token, { expires: 7 }); // Stocker le jeton dans un cookie pour 7 jours

                // Rediriger l'utilisateur vers la page d'accueil
                navigate('/dashboard');
            } else {
                console.error('Failed to sign in');
            }
        } catch (error) {
            console.error('Error during sign in:', error);
        }
    }

    useEffect(() => {
        verifyToken().then(isValid => {
            if (isValid) {
                navigate('/dashboard');
            }
        }).catch(error => {
            if (error.status === 401) {
                console.error('Token is not valid');
            }
        });
    }, []);


    return (
        <Page title="SignIn">
            <div className="container" style={{backgroundImage: 'url(signin.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <h1>{data['title'][lang]}</h1>

                <Back title={data['back'][lang]}/>

                <form onSubmit={handleSubmit}>
                    <label>
                        {data['email'][lang]}
                        <input
                            type="email"
                            name="username"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            />
                    </label>

                    <label>
                        {data['password'][lang]}
                        <input 
                            type="password"
                            name="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            />
                    </label>

                    <Button type="submit" title={data['submit'][lang]} className="button-signin submit"/>

                    <Button to="/signUp" title={data['signUp'][lang]} className="button-signin"/>
                </form>
            </div>
        </Page>
    );
}
