import Cookies from 'js-cookie';
import { useScrollContext } from '../contexts/ScrollContext';
import { useEffect } from 'react';

interface ApiConfig {
    backendUrl: string;
    token: string | undefined;
}

interface getApiConfigProps {
    endpoint?: string;
}

export function getApiConfig({ endpoint } : getApiConfigProps): ApiConfig {
    const mode = process.env.REACT_APP_MODE;

    let baseUrl;
    if (mode && mode === 'dev') {
        baseUrl = process.env.REACT_APP_BACKEND_URL_DEV as string;
    } else {
        baseUrl = process.env.REACT_APP_BACKEND_URL as string;
    }

    const backendUrl = endpoint ? `${baseUrl}/${endpoint}/` : baseUrl as string;
    const token = Cookies.get('token');

    return {
        backendUrl,
        token
    };
}

export function getFrontendUrl(route?: string): string {
    const mode = process.env.REACT_APP_MODE;

    let baseUrl;
    if (mode && mode === 'dev') {
        baseUrl = process.env.REACT_APP_FRONTEND_URL_DEV as string;
    } else {
        baseUrl = process.env.REACT_APP_FRONTEND_URL as string;
    }

    return route ? `${baseUrl}/${route}` : baseUrl as string;
}


export function useScroll(id : string) {
    const { setScroll } = useScrollContext();

    useEffect(() => {
        const rootElement = document.getElementById(id);

        function handleScroll() {
            if (rootElement) { 
                setScroll(rootElement.scrollTop || 0);
            }
        }

        if (!rootElement) {
            return;
        }

        rootElement.addEventListener('scroll', handleScroll, { capture: true });

        return () => {
            rootElement.removeEventListener('scroll', handleScroll, { capture: true });
        };
    }, [ id, setScroll ]);
}
