// Upload.tsx

import React from "react";

import UploadLogo from "../../Utilities/Logos/UploadLogo";
import { useCSVReader, formatFileSize, usePapaParse } from 'react-papaparse';

import { UploadType, onUploadAcceptedType, UploadProps, UploadedFileProps } from "../../../types/Upload";
import { useLangContext } from "../../../contexts/LangContext";
import { CSVDataTOString } from "../../../helpers/CSV&JSON";
import FileLogo from "../../Utilities/Logos/FileLogo";
import Informations from "../../Utilities/Informations";
import Information from "../../Utilities/Information";

import data from '../../../content/CSVParser.json';
import { LangData } from "../../Utilities/Props";


function UploadedFile({ acceptedFile, ProgressBar, getRemoveFileProps, Remove }: UploadedFileProps) {
    return (
        <>
        {acceptedFile != null &&
            <div className="file">
                <div className="left">
                    <FileLogo/>
                </div>

                <div className="right">
                    <div className="top">
                        <div className="info">
                            <span className="name">{acceptedFile.name}</span>
                            <span className="size">
                                {formatFileSize(acceptedFile.size)}
                            </span>
                        </div>

                        <div {...getRemoveFileProps()} className="remove">
                            <Remove color='var(--softGrey)'/>
                        </div>
                    </div>

                    <div className="progressBar">
                        <ProgressBar />
                    </div>
                </div>
            </div>
        }
        </>
    )
}


export default function Upload({ setDataFile, setFile, dataFile } : UploadProps) {
    const lang = useLangContext().lang;
    const { CSVReader } = useCSVReader();
    const { jsonToCSV } = usePapaParse();

    function handleOnUploadAccepted({ dataFile, file, data } : onUploadAcceptedType) {
        if (file) {
            setFile(file);
        }
        if (dataFile) {
            setDataFile(dataFile);
        } else if (data) {
            // on supprime les lignes vides
            data = data.filter((line) => line.length > 0);
            // on supprime qui n'ont qu'un seul élément et que cet élément est vide
            data = data.filter((line) => line.length > 1 || line[0] !== '');
            setDataFile(data);
        }
    }

    function onValidator (file : File) {
        if (file.type === "application/json") {
            const reader = new FileReader();
            reader.onload = (event) => {
                const jsonData = JSON.parse(event.target?.result as string);
                const csvData = CSVDataTOString(jsonToCSV(jsonData, {quotes: true}));

                handleOnUploadAccepted({dataFile : csvData, file : file});
            };
            reader.readAsText(file);
            return file;
        }
        
        setFile(file);
    }

    return (
        <CSVReader
            onUploadAccepted={handleOnUploadAccepted}
            validator={onValidator}
            accept = "text/csv, .csv, application/vnd.ms-excel, .json, application/json"
        >
            {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }: UploadType) => {
                return (
                    <div {...getRootProps()} className={`widget upload ${acceptedFile? 'filed' : ''}`}>
                        <div className={`zone ${acceptedFile? 'filed' : ''}`}>
                            {acceptedFile ? (
                                <UploadedFile
                                    acceptedFile={acceptedFile}
                                    ProgressBar={ProgressBar}
                                    getRemoveFileProps={getRemoveFileProps}
                                    Remove={Remove}
                                />
                            ) : (
                                <>
                                    <UploadLogo />
                                    <span>{(data['drop'] as LangData)[lang]}</span>
                                </>
                            )}
                        </div>

                        {acceptedFile &&
                            <Informations className="upload">
                                <Information label={data['fileName']} value={acceptedFile.name}/>
                                <Information label={data['fileType']} value={acceptedFile.type}/>
                                <Information label={data['fileSize']} value={formatFileSize(acceptedFile.size)}/>
                                {dataFile &&
                                    <>
                                    <Information label={data['linesNumber']} value={dataFile.length - 1}/>
                                    <Information label={data['columnsNumber']} value={dataFile[0].length}/>
                                    </>
                                }
                            </Informations>   
                        }
                    </div>
                );
            }}
        </CSVReader>
    )
}
