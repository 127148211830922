import React from "react";
import { Route, Routes } from "react-router-dom";

import '../../styles/Dashboard/Dashboard.css';
import Results from "./Results/Results";
import Placeholder from "./Placeholder";
import Forms from "./Forms/Forms";
import Form from "./Form/Form";
import Page from "../Utilities/Page";
import SideBar from "./SideBar/SideBar";
import { FormsContextProvider } from "../../contexts/FormsContext";
import CSVParser from "./Results/CSVParser";
import Attendees from "./Attendees/Attendees";
import Settings from "./Settings.tsx/Settings";
import MirrorForm from "./MirrorForm/MirrorForm";
import { ComputeStateContextProvider } from "../../contexts/ComputeStateContext";


export default function Dashboard() {
    return (
        <Page title="DashBoard">
            <FormsContextProvider>
            <ComputeStateContextProvider>
                <SideBar/>

                <Routes>
                    <Route path="/" element={<Placeholder/>}/>
                    <Route path="/results/:formId" element={<Results/>}/>

                    <Route path="/attendees/:formId" element={<Attendees/>}/>

                    <Route path='/forms' element={<Forms/>}/>
                    <Route path='/form' element={<Form/>}/>
                    <Route path='/mirrorForm' element={<MirrorForm/>}/>
                    <Route path='/csvParser' element={<CSVParser/>}/>
                    <Route path='/settings' element={<Settings/>}/>
                </Routes>
            </ComputeStateContextProvider>
            </FormsContextProvider>
        </Page>
    )
}
