import axios from "axios";
import { getApiConfig } from "./utils";


export async function verifyEmail(email: string) {
    const config = getApiConfig({ endpoint: 'verifyEmail' });

    const params = {
        email: email
    };

    const response = await axios.get(config.backendUrl, { params });

    return response;
}


export async function signUp({firstName, lastName, password, email, dob}: {firstName: string, lastName: string, password: string, email: string, dob: string}) {
    const config = getApiConfig({ endpoint: 'signup' });

    const data = {
        firstname: firstName,
        lastname: lastName,
        password: password,
        email: email,
        dob: dob
    };

    const response = await axios.post(config.backendUrl, data);

    return response;
}
