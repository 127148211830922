import axios from "axios";
import { getApiConfig } from "./utils";


export async function verifyToken(): Promise<boolean> {
    const config = getApiConfig({ endpoint: 'verifyToken' });

    try {
        await axios.get(config.backendUrl, {
            headers: {
                Authorization: config.token
            }
        });
        return true;
    } catch (error) {
        // console.error('Error during token check:', error);
        return false;
    }
}


export async function signIn(email: string, password: string): Promise<string | null> {
    const config = getApiConfig({ endpoint: 'signin' });

    const params = {
        "email" : email,
        "password" : password
    };

    try {
        const response = await axios.get(config.backendUrl, { params });
        return response.data.token;
    } catch (error) {
        console.error('Error during sign in:', error);
        return null;
    }
}


export async function logOut() {
    const config = getApiConfig({ endpoint: 'logOut' });

    try {
        await axios.get(config.backendUrl, {
            headers: {
                Authorization: config.token
            }
        });
    } catch (error) {
        console.error('Error during log out:', error);
    }
}
