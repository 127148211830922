// FormsContext.tsx

import React, { createContext, useContext, ReactNode, Dispatch, useReducer } from 'react';

import { computeAction, computeReducer, ComputeState, initialComputeState } from '../reducers/Compute';

// Définissez un type pour votre contexte si nécessaire
interface ComputeStateContextType {
    computeState: ComputeState;
    computeDispatch: Dispatch<computeAction>;
}


// Créez votre contexte avec une valeur par défaut
const ComputeStateContext = createContext<ComputeStateContextType>({
    computeState: {} as ComputeState,
    computeDispatch: () => {},
});


// Créez un hook personnalisé pour utiliser le contexte
function useComputeStateContext() {
    const context = useContext(ComputeStateContext);
    if (!context) {
        throw new Error('useComputeStateContext must be used within a ComputeStateContextProvider');
    }
    return context;
}


// Créez un composant de fournisseur pour votre contexte
interface ComputeStateContextProviderProps {
    children: ReactNode;
}


function ComputeStateContextProvider({ children }: ComputeStateContextProviderProps) {
    // Définissez l'état ou les fonctions que vous souhaitez partager
    const [computeState, computeDispatch] = useReducer(computeReducer, initialComputeState);

    return (
        <ComputeStateContext.Provider value={{ computeState, computeDispatch }}>
            {children}
        </ComputeStateContext.Provider>
    )
}


export { useComputeStateContext, ComputeStateContextProvider };
