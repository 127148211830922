import React from "react";
import Tab from "../Utilities/Tab";

import '../../styles/Dashboard/Dashboard.css';

export default function Placeholder() {
    return (
        <Tab title="Placeholder">
            <div className="widget">
                <img src='/placeholder.jpg' alt="Placeholder"/>
            </div>
        </Tab>
    )
}