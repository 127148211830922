// Questions.tsx

import React from "react";
import { InputType } from "../../../types/Types";
import Input from "./Input";

import '../../../styles/Dashboard/Form.css';

import data from "../../../content/inputs.json";
import { useLangContext } from "../../../contexts/LangContext";
import { LangData } from "../../Utilities/Props";
import GenerateQuestionButton from "./GenerateQuestionButton";
import Button from "../../Utilities/Button";
import { FormType } from "../../../types/Form";
import Information from "../../Utilities/Information";
import Informations from "../../Utilities/Informations";


interface QuestionsProps {
    inputs: InputType[];
    setInputs: (inputs: InputType[]) => void;
    controls?: boolean;
    form?: FormType;
}

export default function Questions({ inputs, setInputs, controls = false, form }: QuestionsProps) {
    const lang = useLangContext().lang;

    function addInput() {
        setInputs([...inputs, { title: '', importance: 0, type: 'text' }]);
    }

    return (
        <div className="widget questions">
            <Informations>
                <Information<string> label={data["questionsNumber"] as LangData} value={inputs.length}/>
            </Informations>

            {controls &&
                <div className='buttons'>
                    <Button onClick={addInput} title={(data["newInput"] as LangData)[lang]}/>
                    {form &&
                        <GenerateQuestionButton form={form} inputs={inputs} setInputs={setInputs} data={data["generateQuestion"]}/>
                    }
                </div>
            }

            <div className='inputs'>
                {inputs.map((input, index) => (
                    <Input
                        key={index}
                        input={input}
                        setInput={(input) => {
                            const updatedInputs = [...inputs];
                            updatedInputs[index] = input;
                            setInputs(updatedInputs);
                        }}
                        removeInput={() => {
                            const updatedInputs = [...inputs];
                            updatedInputs.splice(index, 1);
                            setInputs(updatedInputs);
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
