// Size.tsx

import React, { useEffect, useState } from 'react';

import '../../../styles/Dashboard/Form.css';
import NumberSelector from '../../Utilities/NumberSelector';
import { FormType, InventoryGroupType } from '../../../types/Form';
import Selector from '../../Utilities/Selector';

import allData from '../../../content/form.json';
import { LangData } from '../../Utilities/Props';
import { useLangContext } from '../../../contexts/LangContext';


interface SizeProps {
    form: FormType;
    setForm: (form: FormType) => void;
}

export default function Size({ form, setForm } : SizeProps) {
    const lang = useLangContext().lang;
    const [ groups, setGroups ] = useState<InventoryGroupType[]>();
    const [ nbGroups, setNbGroups ] = useState<number>(1);
    const [ sizeMode, setSizeMode ] = useState<string>('groupSize');

    const data = allData["size"] as Record<string, Record<string, LangData> | LangData>;


    function handleChangeGroupSize(value: number) {
        setSizeMode('groupSize');
        setForm({...form, fixedSize: value});
    }

    function handleChangeNbGroups(value: number) {
        setSizeMode('nbGroups');
        setNbGroups(value);
    }


    useEffect(() => {
        setForm({...form, fixedSize: 2});
    }, []);

    useEffect(() => {
        setNbGroups(groups ? groups.reduce((acc, group) => acc + group.nbGroups, 0) : 0);
    }, [ groups ]);

    useEffect(() => {
        if (form.nbAnswers) {
            if (sizeMode === 'groupSize') {
                setNbGroups(groups ? groups.reduce((acc, group) => acc + group.nbGroups, 0) : 0);

                const groupSize = form.fixedSize;
                const nbAnswers = form.nbAnswers;
                const newGroups = [{ nbGroups: Math.floor(nbAnswers / groupSize), nbAnswersByGroup: groupSize } as InventoryGroupType];

                if (nbAnswers % groupSize !== 0) {
                    newGroups.push({ nbGroups: 1, nbAnswersByGroup: nbAnswers % groupSize } as InventoryGroupType);
                }

                setGroups(newGroups);
            } else {
                if (form.nbAnswers % nbGroups === 0) {
                    setGroups([{ nbGroups: nbGroups, nbAnswersByGroup: form.nbAnswers / nbGroups } as InventoryGroupType]);
                    setForm({...form, fixedSize: Math.floor(form.nbAnswers / nbGroups)});
                } else {
                    setGroups([
                        {
                            nbGroups: Math.floor(form.nbAnswers % nbGroups),
                            nbAnswersByGroup: Math.floor(form.nbAnswers / nbGroups) + 1
                        },
                        {
                            nbGroups: nbGroups - Math.floor(form.nbAnswers % nbGroups),
                            nbAnswersByGroup: Math.floor(form.nbAnswers / nbGroups)
                        }
                    ] as InventoryGroupType[]);
                    setForm({...form, fixedSize: Math.floor(form.nbAnswers / nbGroups) + 1});
                }
            }
        }
    }, [ sizeMode, nbGroups, form.fixedSize ]);


    return (
        <div className="size">
            <div className='selectors'>
                <div>
                    <label>{(data["fixedSize"] as LangData)[lang]}</label>
                    <NumberSelector
                        value={form.fixedSize}
                        setValue={(value) => handleChangeGroupSize(value)}
                        min={1}
                        max={form.nbAnswers}
                    />
                </div>

                <div className='nb-groups'>
                    <label>{(data["nbGroups"] as LangData)[lang]}</label>
                    <NumberSelector
                        value={nbGroups}
                        setValue={(value) => handleChangeNbGroups(value)}
                        min={1}
                        max={form.nbAnswers}
                    />
                </div>

                <div>
                    <label>{(data["priority"] as LangData)[lang]}</label>

                    <Selector
                        selected = {sizeMode}
                        setSelected = {setSizeMode}
                        data = {data["set"] as Record<string, LangData>}
                        className='size-mode-selector'
                    />
                </div>
            </div>

            <div className='inventory'>
                <div className='inventory-line inventory-header'>
                    <span>{(data["nbGroups"] as LangData)[lang]}</span>
                    <span>{(data["groupsSize"] as LangData)[lang]}</span>
                </div>
                {groups && groups.map((group, index) => (
                    <div key={index} className='inventory-line'>
                        <span>{group.nbGroups}</span>
                        <span>{group.nbAnswersByGroup}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}
