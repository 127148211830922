import React from "react";

import Recents from "./Recents";

import '../../../styles/Dashboard/SideBar.css';

import allData from '../../../data.json';
import { LineType, SectionType } from "../../../types/SideBar";
import Section from "./Section";


export default function SideBar() {
    const data : Record<string, SectionType> = allData['SideBar'];

    return (
        <div className="sidebar">
            <Recents/>

            <Section
                title={data.results.title}
                lines={[
                    {
                        'to' : "/dashboard/forms",
                        'title' : data.results.sections.allMyForms
                    }] as LineType[]}
            />

            
            <Section
                title={data.create.title}
                lines={[
                    {
                        'to' : "/dashboard/form",
                        'title' : data.create.sections.createForm
                    },
                    {
                        'to' : "/dashboard/mirrorForm",
                        'title' : data.create.sections.createMirrorForm
                    }] as LineType[]}
            />

            <Section
                title={data.import.title}
                lines={[
                    {
                        'to' : "/dashboard/csvParser",
                        'title' : data.import.sections.csvParser
                    }] as LineType[]}
            />

            <Section
                title={data.settings.title}
                lines={[
                    {
                        'to' : "/dashboard/settings",
                        'title' : data.settings.sections.settings
                    }] as LineType[]}
            />

        </div>
    )
}
