// answers.tsx

import axios from 'axios';

import { getApiConfig } from './utils';
import { FilterType } from '../types/Types';


export async function computeGroups(formId: number) {
    const config = getApiConfig({ endpoint: 'computeGroups' });

    const response = await axios.get(config.backendUrl,
        {
            params: {
                formId
            },
            headers: {
                Authorization: config.token
            }
        }
    );

    return response.data;
}

export async function computeVolumetry(formId: number,
                                       filters?: FilterType[],
                                       answersIds?: number[]) {
    const config = getApiConfig({ endpoint: 'computeVolumetry' });

    const response = await axios.get(config.backendUrl,
        {
            params: {
                formId,
                filters,
                answersIds
            },
            headers: {
                Authorization: config.token
            }
        }
    );

    return response.data;
}
