// Results.tsx

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "../../Utilities/Tab";

import { getAnswers } from "../../../helpers/answers";
import { getForm } from "../../../helpers/forms";
import { computeGroups } from "../../../helpers/compute";

import { FormType } from "../../../types/Form";

import Groups from "./Groups";
import Volumetry from "./Volumetry/Volumetry";
import Primary from "./Primary";

import "../../../styles/Dashboard/Results.css";

import Header from "./Header";
import { useComputeStateContext } from "../../../contexts/ComputeStateContext";
import data from "../../../content/results.json"


export default function Results() {
    const navigate = useNavigate();
    const { formId } = useParams<{ formId: string }>();
    const { computeState, computeDispatch } = useComputeStateContext();

    // const [ answers, setAnswers ] = useState([]);
    const [ attendees, setAttendees ] = useState([]);
    const [ form, setForm ] = useState({} as FormType);

    const [ computing, setComputing ] = useState(true);


    function updateAnswers() {
        if (formId) {
            getAnswers(parseInt(formId)).then((response) => {
                // setAnswers(response.answers);
                setAttendees(response.attendees);
                // console.log("ANSWERS", answers);
                console.log("ATTENDEES", attendees);
            });

            getForm(parseInt(formId)).then((response) => {
                setForm(response);
            });
        }
    }


    function handleCompute() {
        if (formId) {
            computeGroups(parseInt(formId)).then((response) => {
                computeDispatch({ type: "SET_GROUPS", payload: response.groups });
                computeDispatch({ type: "SET_QUESTIONS", payload: response.questions });

                setComputing(false);
            }).catch((error) => {
                if (error.status === 401) {
                    navigate('/signIn');
                }
            });
        }
    }

    useEffect(() => {
        setComputing(true);
    }, [ formId ]);

    useEffect(() => {
        if (!computing) return;
    
        updateAnswers();
        handleCompute();
    }, [ formId, computing ]);


    return (
        <Tab title="Results">
            <Header form={form} updateAnswers={updateAnswers} handleCompute={handleCompute} data={data['header']}/>

            {!computing &&
                <>
                    <Primary inputs={computeState.questions.map((question) => question.question)}/>

                    <Groups form={form}/>
                    <Volumetry form={form}/>
                </>
            }
            
        </Tab>
    )
}
