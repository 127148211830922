// CSVToJson.tsx

export function CSVToJson(data : string[][], columns?: string[]) {
    const headers = data[0];

    const indexToKeep : number[] = [];
    if (columns) {
        headers.forEach((header, index) => {
            if (columns.includes(header)) {
                indexToKeep.push(index);
            }
        });
    } else {
        indexToKeep.push(...Array.from({ length: headers.length }, (_, i) => i));
    }

    const jsonData = data.slice(1).map((row) => {
        return row.reduce((acc, value, index) => {
            if (indexToKeep.includes(index)) {
                acc[headers[index]] = value;
            }
            return acc;
        }, {} as Record<string, string>);
    });

    return jsonData;
}


export function CSVDataTOString(data : string) : string[][] {
    return data.split("\r\n").map((line) => line.split('","').map((cell) => cell.replace("\"", "")));
}