import React, { useState } from "react";

import PricingCard from "./PricingCard";

import "../../../styles/Vitrine/Landing.css";

import allData from "../../../data.json";
import Selector from "../../Utilities/Selector";


export default function Pricing() {
    const [ typeSelected, setTypeSelected ] = useState("subscription");
    const [ periodSelected, setPeriodSelected ] = useState('yearly');

    const data = allData.pricing;

    const priceColors = ["thistle", "var(--orange)", "var(--darkThistle)", "orange"];
    
    return (
        <div className="pricing-container">
            <Selector selected={typeSelected} setSelected={setTypeSelected} data={data.types}/>

            {typeSelected === "subscription" &&
                <>
                    <Selector selected={periodSelected} setSelected={setPeriodSelected} data={data.periods} className="period-selector"/>
                    <div className="plan-container">
                        {
                            data.plans.subscription.map((plan, index) =>
                                <PricingCard key={index} data={plan} period={periodSelected} priceColor={priceColors.at(index)}/>
                            )
                        }
                    </div>
                </>
            }

            {typeSelected === "oneShot" &&
                <div className="plan-container">
                    {
                        data.plans.oneShot.map((plan, index) =>
                            <PricingCard key={index} data={plan} period={periodSelected} priceColor={priceColors.at(index)}/>
                        )
                    }
                </div>
            }
        </div>
    )
}