import { QuestionType } from '../types/Types';
import { GroupType } from '../types/Group';


interface ComputeState {
    groups: GroupType[];
    questions: QuestionType[];
    primaryKeys: Record<number, string>;
}

const initialComputeState: ComputeState = {
    groups: [],
    questions: [],
    primaryKeys: {},
};

type computeAction =
    | { type: 'SET_GROUPS'; payload: GroupType[] }
    | { type: 'SET_QUESTIONS'; payload: QuestionType[] }
    | { type: 'SET_PRIMARY_KEYS'; payload: Record<number, string> };


function computeReducer(state: ComputeState = initialComputeState, action: computeAction): ComputeState {
    switch (action.type) {
        case 'SET_GROUPS':
            action.payload.map((group, index) => {
                group.id = group.id || index;
            });

            return { ...state, groups: action.payload };

        case 'SET_QUESTIONS':
            return { ...state, questions: action.payload };
        case 'SET_PRIMARY_KEYS':
            return { ...state, primaryKeys: action.payload };
        default:
            return state;
    }
}

export { computeReducer, initialComputeState };
export type { ComputeState, computeAction };
