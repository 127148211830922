import React from "react";

import "../../styles/Information.css";

interface InformationsProps {
    children: React.ReactNode;
    className?: string;
}

export default function Informations({ children, className }: InformationsProps) {
    return (
        <div className={`informations ${className ? className : ""}`}>
            {children}
        </div>
    )
}
