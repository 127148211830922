// ExpandLogo.tsx

import React from "react";

import "../../../styles/icon.css";


export default function ExpandLogo() {
    return (
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="expand-logo">
            <path d="M5 1H1M1 1V5M1 1L6 6M13 1H17M17 1V5M17 1L12 6M5 17H1M1 17V13M1 17L6 12M13 17H17M17 
                17V13M17 17L12 12"/>
        </svg>
    );
}
