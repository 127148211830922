import { CardData } from "../components/Utilities/Slider/Card";
import { AnswerType, GroupType } from "./Group";

/**
 * The type of the input
 * title: The title of the input
 * importance: The importance of the input
 * type: The type of the input
*/
export interface InputType {
    title: string;
    importance: number;
    type: string;
}


export interface ExtendedInputType extends InputType {
    id: number;
}

export interface QuestionType {
    id : number;
    question : string;
    answer_type : string;
    values : number;
}

export interface ResponseType {
    id : number;
    question_id : number;
    answer_id : number;
    response : string;
    language : string;
}

export interface LangData {
    [key: string]: string;
}

export interface BlocProps {
    title: LangData;
    subtitle?: LangData;
    description: LangData;
}

export interface DataProps {
    data : Record<string, LangData | CardData[]>
}

export enum FilterType {
    GROUP = 'group',
    QUESTION = 'question',
    ANSWER = 'answer',
}

export type FiltersType = Record<FilterType, QuestionType | GroupType | AnswerType>;

export interface BlocType {
    title: string;
    subtitle?: string;
    description: string;
}
