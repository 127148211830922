import React from "react";

interface SectionProps {
    index: number;
}

export default function Section({ index } : SectionProps) {
    return (
        <div className="section foreground" style={{gridRow: index + 2}}/>
    )
}
