// Switch.tsx

import React from "react";

import '../../styles/Switch.css';


interface SwitchProps {
    checked: boolean;
    setChecked: (checked : boolean) => void;
    className?: string;
}

export default function Switch({ checked, setChecked, className } : SwitchProps) {
    return (
        <div
            className={(className? `switch-container ${className}` : "switch-container") + (checked? " checked" : "")}
            onClick={() => setChecked(!checked)}
        >
            <div
                className={checked? "switch checked" : "switch"}
            />
        </div>
    )
}
