import React, { useEffect, useState } from "react";

import '../../styles/NumberSelector.css';
import { RangeType } from "../../types/Volumetry";


interface NumberSelectorProps {
    value: number;
    setValue: (value: number) => void;

    min?: number;
    max?: number;
    values? : number[];
}

export default function NumberSelector({ value, setValue, values, min, max } : NumberSelectorProps) {
    const [ range, setRange ] = useState<RangeType>({ min: (min || (Number.NEGATIVE_INFINITY)), max: (max || Number.POSITIVE_INFINITY) });

    useEffect(() => {
        if (values && values.length > 0) {
            setRange({
                min: Math.min(...values) > range.min ? Math.min(...values) : range.min,
                max: Math.max(...values) < range.max ? Math.max(...values) : range.max
            });
        }
    }, [ values ]);


    function handleFastDecrease() {
        if (values) {
            if (value <= range.min) {
                return;
            }
            setValue(Math.max(...values.filter((v) => v < value)));
        } else if (Number.isFinite(range.min)) { 
            setValue(range.min);
        }
    }

    function handleFastIncrease() {
        if (values) {
            if (value >= range.max) {
                return;
            }
            setValue(Math.min(...values.filter((v) => v > value)));
        } else if (Number.isFinite(range.max)) {
            setValue(range.max);
        }
    }

    function handleDecrease() {
        if (value <= range.min) {
            return;
        }
        setValue(value - 1);
    }

    function handleIncrease() {
        if (value >= range.max) {
            return;
        }
        setValue(value + 1);
    }


    return (
        <div className="number-selector">
            <input type="number" value={value} onChange={(e) => setValue(parseInt(e.target.value))}/>

            <div className="buttons">
                {values && 
                    <button onClick={handleFastDecrease}>
                        <span>--</span>
                    </button>
                }
                <button onClick={handleDecrease}>
                    <span>-</span>
                </button>
                <button onClick={handleIncrease}>
                    <span>+</span>
                </button>
                {values && 
                    <button onClick={handleFastIncrease}>
                        <span>++</span>
                    </button>
                }
            </div>

        </div>
    )
}