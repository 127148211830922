// App.tsx

import React from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import '../styles/size.css';
import '../styles/color.css';
import '../styles/App.css';

import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import FillForm from './FillForm.tsx/FillForm';
import Dashboard from './Dashboard/Dashboard';
import { LangContextProvider, useLangContext } from '../contexts/LangContext';
import FillFormByLink from './FillForm.tsx/FillFormByLink';
import { ScrollContextProvider } from '../contexts/ScrollContext';
import Vitrine from './Vitrine/Vitrine';


function Content() {
    const lang = useLangContext().lang;

    return (
        <>
            <Helmet>
                <meta name="theme-color" content="rgb(253, 244, 227)"/>
                <html lang={lang}/>
            </Helmet>

            <div className="page">
                <Routes>
                    <Route path="/*" element={<Vitrine/>}/>

                    <Route path="/signIn" element={<SignIn/>}/>
                    <Route path="/signUp" element={<SignUp/>}/>

                    <Route path="/fillForm/:uuid" element={<FillForm/>}/>
                    <Route path="/fillFormByLink/:uuid" element={<FillFormByLink/>}/>

                    <Route path="/dashboard/*" element={<Dashboard/>}/>
                </Routes>
            </div>
        </>
    )
}


export default function App() {
    return (
        <BrowserRouter>
            <ScrollContextProvider>
                <LangContextProvider>
                    <Content/>
                </LangContextProvider>
            </ScrollContextProvider>
        </BrowserRouter>
    );
}
