// FileLogo.tsx

import React from 'react';

import '../../../styles/icon.css';


export default function FileLogo() {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className='file-logo'>
            <path d="M14 21.25H10V22.75H14V21.25ZM2.75 14V9.99999H1.25V14H2.75ZM21.25 13.563V14H22.75V13.563H21.25ZM14.891 
                4.61099L18.85 8.17399L19.853 7.05799L15.895 3.49499L14.891 4.61099ZM22.75 13.563C22.75 11.874 22.765 10.804 
                22.34 9.84799L20.969 10.459C21.235 11.057 21.25 11.742 21.25 13.563H22.75ZM18.85 8.17399C20.203 9.39199 
                20.703 9.86199 20.969 10.459L22.34 9.84799C21.914 8.89099 21.109 8.18799 19.853 7.05799L18.85 8.17399ZM10.03 
                2.74999C11.612 2.74999 12.209 2.76199 12.74 2.96599L13.278 1.56599C12.426 1.23799 11.498 1.24999 10.03 
                1.24999V2.74999ZM15.895 3.49599C14.809 2.51899 14.13 1.89199 13.278 1.56599L12.741 2.96599C13.273 3.16999 
                13.721 3.55799 14.891 4.61099L15.895 3.49599ZM10 21.25C8.093 21.25 6.739 21.248 5.71 21.11C4.705 20.975 
                4.125 20.721 3.702 20.298L2.642 21.358C3.39 22.108 4.339 22.439 5.511 22.597C6.661 22.752 8.136 22.75 10 
                22.75V21.25ZM1.25 14C1.25 15.864 1.248 17.338 1.403 18.489C1.561 19.661 1.893 20.61 2.641 21.359L3.701 
                20.299C3.279 19.875 3.025 19.295 2.89 18.289C2.752 17.262 2.75 15.907 2.75 14H1.25ZM14 22.75C15.864 22.75 
                17.338 22.752 18.489 22.597C19.661 22.439 20.61 22.107 21.359 21.359L20.299 20.299C19.875 20.721 19.295 
                20.975 18.289 21.11C17.262 21.248 15.907 21.25 14 21.25V22.75ZM21.25 14C21.25 15.907 21.248 17.262 21.11 
                18.29C20.975 19.295 20.721 19.875 20.298 20.298L21.358 21.358C22.108 20.61 22.439 19.661 22.597 18.489C22.752 
                17.339 22.75 15.864 22.75 14H21.25ZM2.75 9.99999C2.75 8.09299 2.752 6.73899 2.89 5.70999C3.025 4.70499 3.279 
                4.12499 3.702 3.70199L2.642 2.64199C1.892 3.38999 1.561 4.33899 1.403 5.51099C1.248 6.66099 1.25 8.13599 1.25 
                9.99999H2.75ZM10.03 1.24999C8.155 1.24999 6.674 1.24799 5.519 1.40299C4.342 1.56099 3.39 1.89299 2.641 
                2.64099L3.701 3.70099C4.125 3.27899 4.706 3.02499 5.718 2.88999C6.751 2.75199 8.113 2.74999 10.03 
                2.74999V1.24999Z"/>
            <path d="M13 2.5V5C13 7.357 13 8.536 13.732 9.268C14.464 10 15.643 10 18 10H22"/>
        </svg>
    )
}
