// HideSelector.tsx

import React, { useState, useEffect, useRef } from "react";

import "../../styles/HideSelector.css";

interface HideSelectorProps<T> {
    selectedIndex: number;
    setSelectedIndex: (index : number) => void;
    options: T[];
    className?: string;
}

export default function HideSelector({ selectedIndex, setSelectedIndex, options, className }: HideSelectorProps<string>) {
    const [expanded, setExpanded] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setExpanded(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (!expanded) return;

        switch (event.key) {
            case "ArrowDown":
                event.preventDefault();
                setHighlightedIndex((prevIndex) => (Math.min(prevIndex + 1, options.length - 1)));
                break;
            case "ArrowUp":
                event.preventDefault();
                setHighlightedIndex((prevIndex) => (Math.max(prevIndex - 1, 0)));
                break;
            case "Enter":
                event.preventDefault();
                if (highlightedIndex !== null) {
                    setSelectedIndex(highlightedIndex);
                    setExpanded(false);
                }
                break;
            case "Escape":
                event.preventDefault();
                setExpanded(false);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [ expanded, highlightedIndex ]);

    return (
        <div
            ref={containerRef}
            className={className ? `hide-selector ${className}` : "hide-selector"}
            onClick={() => setExpanded(!expanded)}
        >
            <div className="current">
                <span>{options[selectedIndex]}</span>
            </div>
            
            {expanded &&
                <div className="options">
                    {options.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => setSelectedIndex(index)}
                            className={`option ${options[selectedIndex] === item ? "selected" : ""} ${highlightedIndex === index ? "highlighted" : ""}`}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            }
        </div>
    );
}
