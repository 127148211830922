import React, { useRef, useState } from "react";

import { QRCodeSVG } from "qrcode.react";

import Button from "../../Utilities/Button";
import { getFrontendUrl } from "../../../helpers/utils";
import { FormType } from "../../../types/Form";

import "../../../styles/Dashboard/Results.css";
import ExpandLogo from "../../Utilities/Logos/ExpandLogo";


interface QRCodeWidgetProps {
    form: FormType;
}

export default function QRCodeWidget({ form }: QRCodeWidgetProps) {
    const link = getFrontendUrl(`fillFormByLink/${form.uuid}`);
    const svgRef = useRef<SVGSVGElement>(null);
    const [ format, setFormat ] = useState<'png' | 'jpeg' | 'svg'>('png');
    const [ expanded, setExpanded ] = useState(false);

    function copyToClipboard() {
        navigator.clipboard.writeText(link).then(() => {
            
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    }

    function download(format: 'png' | 'jpeg' | 'svg') {
        const svg = svgRef.current;
        if (!svg) return;

        const svgData = new XMLSerializer().serializeToString(svg);
        const link = document.createElement('a');

        if (format === "svg") {
            const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
            link.href = URL.createObjectURL(blob);
            link.download = `${form.title}-QR.${format}`;
            link.click();
        } else {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
    
            const img = new Image();
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx?.drawImage(img, 0, 0);
    
                link.href = canvas.toDataURL(`image/${format}`);
                link.download = `${form.title}-QR.${format}`;
                link.click();
            };
            img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
        }
    }

    function handleDownload() {
        download(format);
    }

    function handleExpand() {
        setExpanded(!expanded);
    }

    return (
        <div className="widget qrcode">
            <div className={expanded ? `qrcode-container expanded` : 'qrcode-container'} onClick={handleExpand}>
                {expanded &&
                    <h1>{form.title}</h1>
                }
                <QRCodeSVG
                    value={link}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    ref={svgRef}
                    className="qrcode-svg"
                />
            </div>

            <div className="controls">
                <Button onClick={copyToClipboard} title="Copy Link" className="copy-button"/>

                <div className="expand-button" onClick={handleExpand}>
                    <ExpandLogo/>
                </div>  
            </div>

            <div className="download-options">
                <div className="select-option">
                    <label>Format</label>
                    <select value={format} onChange={(e) => setFormat(e.target.value as 'png' | 'jpeg' | 'svg')}>
                        <option value="png">PNG</option>
                        <option value="jpeg">JPEG</option>
                        <option value="svg">SVG</option>
                    </select>
                </div>

                <Button onClick={handleDownload} title={`Download`}/>
            </div>
        </div>
    );
}