import React, { useEffect } from "react";

import { getForms } from "../../../helpers/forms";

import '../../../styles/Dashboard/SideBar.css';
import { useFormsContext } from "../../../contexts/FormsContext";
import { useNavigate } from "react-router-dom";


export default function Recents() {
    const { forms, setForms } = useFormsContext();
    const navigate = useNavigate();

    function updateForms() {
        getForms().then((data) => {
            if (Array.isArray(data)) {
                const sortedData = data.sort((a, b) => (b.id || -1) - (a.id || -1));
                setForms(sortedData);
            } else {
                console.error("Les données récupérées ne sont pas un tableau :", data);
            }
        }).catch(error => {
            console.error("Erreur lors de la récupération des formulaires :", error);
        });
    }

    useEffect(() => {
        updateForms();
    }, []);

    return (
        <>
        {forms.length > 0 &&
            <div className="recent-container widget">
                <h3>Recents</h3>

                {forms.slice(0, 3).map((form, index) => (
                    <div key={index} className="recent" onClick={() => (navigate(`/dashboard/results/${form.id}`))}>
                        {form.title}
                    </div>
                ))}

            </div>
        }
        </>
    )
}
