// Answer.tsx

import React, { useState } from "react";

import { AnswerType } from "../../../types/Group";
import { useComputeStateContext } from "../../../contexts/ComputeStateContext";


interface AnswerProps {
    answer: AnswerType;
}

export default function Answer({ answer } : AnswerProps) {
    const computeState = useComputeStateContext().computeState;
    const [ expanded, setExpanded ] = useState(false);

    function handleClick() {
        setExpanded(!expanded);
    }

    return (
        <div className="answer" onClick={(e) => {e.stopPropagation(); handleClick()}}>
            {answer.answer.map((response, responseIndex) => {
                if (!expanded && computeState.primaryKeys && response.question_id in computeState.primaryKeys) {
                    return (
                        <div key={responseIndex} className="entrie">
                            <span className="key">{computeState.primaryKeys[response.question_id]}</span>
                            <span className="value">{response.response}</span>
                        </div>
                    )
                } else if (expanded) {
                    return (
                        <div key={responseIndex} className="entrie">
                            <span className="key">
                                {computeState.questions.find((question) => question.id === response.question_id)?.question}
                            </span>
                            <span className="value">{response.response}</span>
                        </div>
                    )
                }
            })}
        </div>
    )
}
