import React from "react";

import { DataProps, LangData } from "../../../types/Types";
import Button from "../../Utilities/Button";
import { useLangContext } from "../../../contexts/LangContext";

import "../../../styles/Vitrine/Landing.css";


export default function Hero({ data } : DataProps) {
    const lang = useLangContext().lang;

    return(
        <div className="hero-container">
            <div className="hero">
                <div className="text">
                    <h1>{(data["tagline"] as LangData)[lang]}</h1>
                    <h2>{(data["subheading"] as LangData)[lang]}</h2>
                
                    <Button to="/signUp" title={(data["callToAction"] as LangData)[lang]} className="signup"/>
                </div>

                <img src="/hero.webp" alt="Landing"/>
            </div>
        </div>
    )
}
