// UseCases.tsx

import React, { useEffect, useState } from "react";

import { BlocProps } from "../../Utilities/Props";
import { useLangContext } from "../../../contexts/LangContext";

import "../../../styles/Vitrine/Landing.css";
import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";


interface UseCaseProps {
    data : BlocProps;
    className?: string;
}


function UseCase({ data, className } : UseCaseProps) {
    const lang = useLangContext().lang;

    return (
        <div className={className ? `use-case ${className}` : 'use-case'}>
            <span>{data.title[lang]}</span>
        </div>
    )
}


interface UseCasesProps {
    data: BlocProps[];
}


interface SliderProps extends UseCasesProps {
    direction?: "ltr" | "rtl";
    start?: number;
}


function Slider({ data, direction = 'ltr', start} : SliderProps) {
    const slipeDir = direction == "rtl" ? "ltr" : "rtl";
    const [randoms, setRandoms ] = useState<number[]>([]);

    function getRandom(excludedIndices: number[]) {
        const availableIndices = Array.from({ length: data.length }, (_, i) => i).filter(
            (index) =>
                !excludedIndices.includes(index) &&
                !excludedIndices.includes(index + 1) &&
                !excludedIndices.includes(index - 1)
        );
        const randomIndex = Math.floor(Math.random() * availableIndices.length);
        return availableIndices[randomIndex];
    }

    useEffect(() => {
        const currentRandoms: number[] = [];
        for (let i = 0; i < data.length / 8; i++) {
            currentRandoms.push(getRandom(currentRandoms));
        }

        setRandoms(currentRandoms);
    }, [ data.length ]);

    return (
        <Splide
            options={{
                type: "loop", // Loop back to the beginning when reaching the end
                autoScroll: {
                    pauseOnHover: true, // Do not pause scrolling when hovering over the carousel
                    pauseOnFocus: true, // Do not pause scrolling when the carousel is focused
                    rewind: true, // Rewind to start when the end is reached
                    speed: 0.5 // Scrolling speed
                },
                pagePerView: 1, // Number of slides per page
                arrows: false, // Hide navigation arrows
                pagination: false, // Hide pagination dots

                fixedWidth: 'calc(30 * var(--scale))', // Fixed width for each slide
                display: 'flex', // Allow the carousel to grow to fill the available space
                flexDirection: 'row', // Arrange the slides horizontally
                direction: slipeDir, // Set the direction of the carousel,
                start: start
            }}
            extensions={{ AutoScroll }} // Use the AutoScroll extension
        >
            {data.map((useCase, index) => (
                <SplideSlide key={index}>
                    <UseCase data={useCase} className={randoms.some((i) => i == index) ? "special" : ""}/>
                </SplideSlide>
            ))}
        </Splide>
    )
}


export default function UseCases({ data } : UseCasesProps) {
    // on compte le nombre d'élements dans data
    const dataLength = data.length;

    // on fait une division entière par 3
    const start = Math.floor(dataLength / 3);

    return (
        <div className='usecases-container'>
            <Slider data={data} direction="rtl" start={0}/>
            <Slider data={data} start={start}/>
            <Slider data={data} direction="rtl" start={start * 2}/>
        </div>
    )
}
