// forms.tsx

import axios from 'axios';

import { getApiConfig } from './utils';

import { InputType } from '../types/Types';
import { FormType } from '../types/Form';


export async function getForms() : Promise<FormType[]> {
    const config = getApiConfig({ endpoint: 'getUserForms' });

    const response = await axios.get(config.backendUrl, {
        headers: {
            Authorization: config.token
        }
    });

    return response.data.forms;
}


export async function getForm(formId: number) {
    const config = getApiConfig({ endpoint: 'getForm' });

    const response = await axios.get(config.backendUrl, {
        headers: {
            Authorization: config.token
        },
        params: {
            formId
        }
    });

    return response.data.form;
}


export async function deleteForm(formId: number, creatorId: number) {
    const config = getApiConfig({ endpoint: 'deleteForm' });

    await axios.delete(config.backendUrl,
        {
            headers: {
                Authorization: config.token
            },
            data: {
                formId,
                creatorId
            }
        }
    );
}


interface FormProps {
    inputs: InputType[];
    form: FormType;
}

export async function submitForm({ form, inputs }: FormProps) : Promise<FormType | null> {
    const config = getApiConfig({ endpoint: 'submitForm' });

    const data = {
        title : form.title,
        description : form.description,
        fixedSize : form.fixedSize,
        minSize : form.minSize,
        maxSize : form.maxSize,
        inputs : inputs
    }

    try {
        const response = await axios.post(config.backendUrl, data, {
            headers: {
                Authorization: config.token
            }
        });

        return response.data['form'] as FormType;
    } catch (error) {
        console.error('Error during sign in:', error);
        return null;
    }
}


export function sendForm(formId: number) {
    const config = getApiConfig({ endpoint: 'sendForm' });

    axios.post(config.backendUrl, 
        {"formId": formId},
        {
        headers: {
            Authorization: config.token
        }
    });
}


export async function get_form_by_answer_uuid(uuid: string) {
    const config = getApiConfig({ endpoint: 'getFormByAnswerUUID' });

    return axios.get(config.backendUrl, {
        headers: {
            Authorization: config.token
        },
        params: {
            uuid
        }
    });
}

export async function generateQuestion(form : FormType,
                                       inputs : InputType[]) {
    const config = getApiConfig({ endpoint: 'generateQuestion' });

    const previousQuestions = inputs.map((input) => {
        return input.title;
    });
    
    console.log("FORM", form);
    console.log("PREVIOUS", previousQuestions);


    const response = axios.get(config.backendUrl, {
        headers: {
            Authorization: config.token
        },
        params: {
            formTitle: form.title,
            formDescription: form.description,
            previousQuestions: previousQuestions
        }
    });

    return (await response).data['question'];
}
