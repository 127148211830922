import React, { useEffect, useState } from "react";

import '../../styles/Input.css';
import { LangData } from "./Props";
import { useLangContext } from "../../contexts/LangContext";

interface FormInputProps {
    label?: string;
    langData?: LangData;
    value: string;
    setValue: (value: string) => void;
    type?: React.HTMLInputTypeAttribute;
    placeholder?: string;
    className?: string;
}

export default function FormInput({ value, setValue, label, type, placeholder, langData, className }: FormInputProps) {
    const lang = useLangContext().lang;
    const [ title, setTitle ] = useState<string>();

    useEffect(() => {
        if (langData) {
            setTitle(langData[lang]);
        } else if (label) {
            setTitle(label);
        }
    }, [ lang, langData, label ]);

    
    return (
        <div className={className ? `form-input ${className}` : "form-input"}>
            {title && <label>{title || label}</label>}

            <input
                type={type || "text"}
                placeholder={placeholder || title ||label}
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </div>
    )
}