import axios from "axios";
import { getApiConfig } from "./utils";

export default async function getNbUsers() {
    const config = getApiConfig({ endpoint: 'getNbUsers' });

    const response = await axios.get(config.backendUrl,
        {
            headers: {
                Authorization: config.token
            }
    });

    return response.data;
}
