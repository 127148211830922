import React from "react";
import { InputType } from "../../../types/Types";
import { useComputeStateContext } from "../../../contexts/ComputeStateContext";


interface PrimaryProps {
    inputs: (string | InputType)[];
}

export default function Primary({ inputs }: PrimaryProps) {
    const { computeState, computeDispatch } = useComputeStateContext();

    function handleCheck(value : string) {
        const question = computeState.questions.find((question) => question.question === value);

        // on vérifie si question.id est déjà dans primaryKeys
        if (question && question.id in computeState.primaryKeys) {
            const newPrimaryKeys = { ...computeState.primaryKeys };
            delete newPrimaryKeys[question.id];
            computeDispatch({ type: 'SET_PRIMARY_KEYS', payload: newPrimaryKeys });
        } else if (question) {
            computeDispatch({ type: 'SET_PRIMARY_KEYS', payload: { ...computeState.primaryKeys, [question.id]: value } });
        }
    }

    return (
        <div className="widget">
            <h2>Primary Keys</h2>

            {inputs.map((input, index) => {
                if (typeof input === 'string') {
                    return (
                        <div key={index} className="checkbox-line">
                            <input type="checkbox" value={input} onChange={(e) => handleCheck(e.target.value)}/>
                            <label>{input}</label>
                        </div>
                    )
                } else if (typeof input === 'object') {
                    return (
                        <div key={index} className="checkbox-line">
                            <input type="checkbox" value={input.title} onChange={(e) => handleCheck(e.target.value)}/>
                            <label>{input.title}</label>
                        </div>
                    )
                }
                return null;
            })
        }
        </div>
    )
}
