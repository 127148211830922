// Section.tsx

import React from "react";
import { useLangContext } from "../../../contexts/LangContext";
import { LineType } from "../../../types/SideBar";
import { useNavigate } from "react-router-dom";
import { LangData } from "../../Utilities/Props";


function Line({title, onClick, to} : LineType) {
    const lang = useLangContext().lang;
    const navigate = useNavigate();

    function handleClick() {
        if (onClick) {
            onClick();
        }

        if (to) {
            navigate(to);
        }
    }

    return(
        <div className="line" onClick={handleClick}>
            {title[lang]}
        </div>
    )
}

interface SectionProps {
    title: LangData;
    lines : LineType[];
}

export default function Section({title, lines} : SectionProps) {
    const lang = useLangContext().lang;

    return(
        <div className="sidebar-section widget">
            <h3>{title[lang]}</h3>
            {lines.map((line, index) => (
                <Line key={index} {...line}/>
            ))}
        </div>
    )
}
