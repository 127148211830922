// Header.tsx

import React from "react";
import Button from "../../Utilities/Button";
import { FormType } from "../../../types/Form";

import "../../../styles/Dashboard/Results.css";
import QRCodeWidget from "./QRCodeWidget";
import InboxLogo from "../../Utilities/Logos/InboxLogo";
import { LangData } from "../../Utilities/Props";
import { useLangContext } from "../../../contexts/LangContext";


interface HeaderProps {
    form: FormType;
    updateAnswers: () => void;
    handleCompute: () => void;
    data: Record<string, LangData>;
}

export default function Header({ form, updateAnswers, handleCompute, data } : HeaderProps) {
    const lang = useLangContext().lang;

    return (
        <div className="header-container">
            <div className="widget header">
                <div className="top">
                    <h1>{data['results'][lang]}</h1>
                    {form.nbAnswers &&
                        <div className="nb-answers">
                            <InboxLogo/>
                            <span>
                                {form.nbAnswers} {form.nbAnswers > 1? data['answersReceived'][lang] : data['answerReceived'][lang]}
                            </span>
                        </div>
                    }
                </div>

                <h2>{form.title}</h2>

                <Button title="Refresh" onClick={updateAnswers}/>
                <Button title="Compute" onClick={handleCompute} className="compute-button"/>
            </div>

            <QRCodeWidget form={form}/>
        </div>
    );
}