import React, { useEffect, useState } from "react";

import Selector from "../../../Utilities/Selector";
import { LangData } from "../../../Utilities/Props";
import HideSelector from "../../../Utilities/HideSelector";
import Switch from "../../../Utilities/Switch";

import { useLangContext } from "../../../../contexts/LangContext";
import { useComputeStateContext } from "../../../../contexts/ComputeStateContext";

import { FiltersType, FilterType } from "../../../../types/Types";

import allData from "../../../../data.json";


interface FilterProps<T> {
    title: LangData;

    options: T[];
    optionSelectedIndex: number;
    setOptionSelectedIndex: (optionSelectedIndex : number) => void;

    selected: boolean;
    setSelected: (selected : boolean) => void;
}

function Filter({ title, options, optionSelectedIndex, setOptionSelectedIndex, selected, setSelected } : FilterProps<string>) {
    const lang = useLangContext().lang;

    return (
        <div className="filter">
            <div className="filter-title">
                <span>{title[lang]}</span>
                <Switch checked={selected} setChecked={setSelected}/>
            </div>

            {selected &&
                <HideSelector
                    selectedIndex = {optionSelectedIndex}
                    setSelectedIndex = {setOptionSelectedIndex}
                    options = {options}
                />
            }
        </div>
    )
}


interface FiltersProps {
    asc : boolean;
    setAsc : (asc : boolean) => void;
    orderData: Record<string, LangData | Record<string, LangData>>;

    defaultFilters: FilterType[];
    filters : FiltersType;
    setFilters : (filters : FiltersType) => void;
    filterData: Record<string, LangData | Record<string, LangData>>;
}

export default function Filters({ asc, setAsc, defaultFilters, filters, setFilters, orderData, filterData } : FiltersProps) {
    const lang = useLangContext().lang;

    const computeState = useComputeStateContext().computeState;
    const [ selectedGroupIndex, setSelectedGroupIndex ] = useState<number>(0);
    const [ selectedQuestionIndex, setSelectedQuestionIndex ] = useState<number>(0);


    function handleGroupSelected(selected : boolean) {
        if (selected) {
            setFilters({ ...filters, [FilterType.GROUP]: computeState.groups[selectedGroupIndex] });
        } else {
            const { [FilterType.GROUP]: _, ...rest } = filters; // eslint-disable-line
            setFilters(rest as FiltersType);
        }
    }

    function handleQuestionSelected(selected : boolean) {
        if (selected) {
            setFilters({ ...filters, [FilterType.QUESTION]: computeState.questions[selectedQuestionIndex] });
        } else {
            const { [FilterType.QUESTION]: _, ...rest } = filters; // eslint-disable-line
            setFilters(rest as FiltersType);
        }
    }

    useEffect(() => {
        handleGroupSelected(Object.keys(filters).includes(FilterType.GROUP));
    }, [selectedGroupIndex]);

    useEffect(() => {
        handleQuestionSelected(Object.keys(filters).includes(FilterType.QUESTION));
    }, [selectedQuestionIndex]);



    return (
        <div className="filters-container">
            <div className="order">
                <span>{(orderData["title"] as LangData)[lang]}</span>
                <Selector
                    selected={asc ? "ascending" : "descending"}
                    setSelected={(selected) => setAsc(selected === "ascending")}
                    data={orderData["options"] as Record<string, LangData>}
                />
            </div>

            <div className="filters">
                <span>{(filterData["title"] as LangData)[lang]}</span>

                {!defaultFilters.includes(FilterType.GROUP) &&
                    <Filter
                        title = {filterData.options["group"] as LangData}
                        options = {computeState.groups.map((group) => group.name || `${(allData['group'] as LangData)[lang]} ${group.id + 1}`)}
                        optionSelectedIndex = {selectedGroupIndex}
                        setOptionSelectedIndex = {setSelectedGroupIndex}

                        selected = {Object.keys(filters).includes(FilterType.GROUP)}
                        setSelected = {(selected) => handleGroupSelected(selected)}
                    />
                }

                {!defaultFilters.includes(FilterType.QUESTION) &&
                    <Filter
                        title={filterData.options["question"] as LangData}
                        options={computeState.questions.map((question) => question.question)}
                        optionSelectedIndex={selectedQuestionIndex}
                        setOptionSelectedIndex={setSelectedQuestionIndex}
                        selected={Object.keys(filters).includes(FilterType.QUESTION)}
                        setSelected={(selected) => handleQuestionSelected(selected)}
                    />
                }

            </div>
        </div>
    )
}
