// Groups.tsx

import React from "react";

import Group from "./Group";
import { FormType } from "../../../types/Form";
import { useComputeStateContext } from "../../../contexts/ComputeStateContext";

import allData from "../../../data.json";
import { LangData } from "../../Utilities/Props";
import { useLangContext } from "../../../contexts/LangContext";


interface GroupsProps {
    form : FormType;
}

export default function Groups({ form } : GroupsProps) {
    const lang = useLangContext().lang;
    const computeState = useComputeStateContext().computeState;

    return (
        <div className="widget groups">
            <h3>{(allData['groups'] as LangData)[lang]}</h3>

            <div className="groups-container">
                {computeState.groups.map((group, index) => (
                    <Group key={index} group={group} form={form}/>
                ))}
            </div>
        </div>
    );
}
