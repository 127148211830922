import React from "react";
import NumberSelector from "../../../Utilities/NumberSelector";
import { RangeType } from "../../../../types/Volumetry";
import { LangData } from "../../../Utilities/Props";
import { useLangContext } from "../../../../contexts/LangContext";

interface RangeValuesProps {
    rangeValues: RangeType;
    setRangeValues: (rangeValues: RangeType) => void;
    values?: number[];
    data: Record<string, LangData>;
}

export default function RangeValues({ rangeValues, setRangeValues, values, data }: RangeValuesProps) {
    const lang = useLangContext().lang;
    
    return (
        <div className="range">
            <div>
                <span>{(data["min"] as LangData)[lang]}</span>
                <NumberSelector
                    value={rangeValues.min}
                    setValue={(value) => setRangeValues({ ...rangeValues, min: value })}
                    values={values}
                />
            </div>

            <div>
                <span>{(data["max"] as LangData)[lang]}</span>
                <NumberSelector
                    value={rangeValues.max}
                    setValue={(value) => setRangeValues({ ...rangeValues, max: value })}
                    values={values}
                />
            </div>
        </div>
    )
}
