// Group.tsx

import React, { useState } from "react";

import Volumetry from "./Volumetry/Volumetry";

import { FormType } from "../../../types/Form";
import { GroupType } from "../../../types/Group";
import { FilterType } from "../../../types/Types";

import PieGraph from "../../Utilities/PieGraph";

import allData from "../../../data.json";

import Answer from "./Answer";
import { useLangContext } from "../../../contexts/LangContext";
import { LangData } from "../../Utilities/Props";


interface GroupProps {
    group : GroupType;
    form : FormType;
}

export default function Group({ group, form } : GroupProps) {
    const lang = useLangContext().lang;

    const [ selected, setSelected ] = useState<boolean>(false);

    return (
        <div className="group" onClick={() => setSelected(!selected)} style={selected ? {width : "100%"} : {}}>
            <h4>{group.name || `${(allData["group"] as LangData)[lang]} ${group.id + 1}`}</h4>

            <PieGraph progress={Math.round(group.score * 100)}/>

            {group.group.map((answer, answerIndex) => (
                <Answer key={answerIndex} answer={answer}/>
            ))}

            {selected &&
                <Volumetry
                    form={form}
                    filters={[FilterType.GROUP]}
                    answersIds={group.group.map((answer) => answer.id)}
                />
            }
        </div>
    );
}
